import React, { useEffect, useState } from 'react';
import { Row, Col , Card,FloatingLabel, Form ,Button} from 'react-bootstrap';


const FinalizarJogo = ({ jogo, finalizarJogo, jogadores, voltar}) => {
    //Desenhar 3 selects para escolher a equipa do melhor jogador, o melhor jogador e a melhor claque.
    //No final, um botão para finalizar o jogo.
    const [{ equipa_selected, jogador_selected ,claque_selected,equipagr_selected,jogadorgr_selected}, setData] = useState({
        equipa_selected: 'none',
        jogador_selected: 'none',
        claque_selected: 'none',
        equipagr_selected: 'none',
        jogadorgr_selected: 'none',

    });

    const [jogadores_select, setJogadoresSelect] = useState(null);
    const [jogadoresgr_select, setJogadoresGRSelect] = useState(null);
    const [finalizarDisponivel, setFinalizarDisponivel] = useState(false);

    useEffect(() => {
        checkFinalizarDisponivel();
    }, [equipa_selected, jogador_selected, claque_selected]);

    const checkFinalizarDisponivel = () => {
        if (equipa_selected !== 'none' && jogador_selected !== 'none' && claque_selected !== 'none') {
            setFinalizarDisponivel(true);
        } else {
            setFinalizarDisponivel(false);
        }
    }


    function handleEquipaChange(event) {
        setData(data => ({ ...data,equipa_selected: event.target.value, jogador_selected: 'none' }));
        setJogadoresSelect(calculaJogadores(event.target.value));
    }

    function handleEquipaGRChange(event) {
        setData(data => ({ ...data,equipagr_selected: event.target.value, jogadorgr_selected: 'none' }));
        setJogadoresGRSelect(calculaJogadores(event.target.value));
    }

    function handleJogadorChange(event) {
        setData(data => ({ ...data, jogador_selected: event.target.value }));
    }

    function handleJogadorGRChange(event) {
        setData(data => ({ ...data, jogadorgr_selected: event.target.value }));
    }

    function handleClaqueChange(event) {
        setData(data => ({ ...data, claque_selected: event.target.value }));
    }

    const calculaJogadores = (equipa) => {
        return jogadores.filter(jogador => jogador.equipas_id == equipa && jogador.tipo == 1);
    }

    const equipas_data = jogo ? [<option key="equipas-0" value="none"></option>,
        <option key={`equipas-${jogo.equipacasaid}`} value={jogo.equipacasaid}>
            {jogo.equipacasa}
        </option>,
        <option key={`equipas-${jogo.equipaforaid}`} value={jogo.equipaforaid}>
        {jogo.equipafora}
    </option>
    ] : null;

    const equipasgr_data = jogo ? [<option key="equipasgr-0" value="none"></option>,
        <option key={`equipasgr-${jogo.equipacasaid}`} value={jogo.equipacasaid}>
            {jogo.equipacasa}
        </option>,
        <option key={`equipasgr-${jogo.equipaforaid}`} value={jogo.equipaforaid}>
        {jogo.equipafora}
    </option>
    ] : null;

    const jogadores_data = jogadores ? [<option key="jogadores-0" value="none"></option>, jogadores_select?.map((item) => (
        <option key={`jogadores-${item.id}`} value={item.id}>
             {item.numero ? `${item.numero} - ` : ''}{item.nomecurto}
        </option>
    ))] : null;

    const jogadoresgr_data = jogadores ? [<option key="jogadoresgr-0" value="none"></option>, jogadoresgr_select?.map((item) => (
        <option key={`jogadoresgr-${item.id}`} value={item.id}>
             {item.numero ? `${item.numero} - ` : ''}{item.nomecurto}
        </option>
    ))] : null;

    const claques_data = jogo ? [<option key="claques-0" value="none"></option>,
        <option key={`claques-${jogo.equipacasaid}`} value={jogo.equipacasaid}>
            {jogo.equipacasa}
        </option>,
        <option key={`claques-${jogo.equipaforaid}`} value={jogo.equipaforaid}>
        {jogo.equipafora}
    </option>,
        <option key={`claques-00`} value='0'>
        Nenhuma se destacou
        </option>
    ] : null;

    return (
        <>
        <Card>
            <Card.Header>
                            <Card.Title>Melhor Jogador</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect" label="Equipa">
                                        <Form.Select aria-label="Equipa"
                                            onChange={handleEquipaChange}
                                            value={equipa_selected}>
                                            {equipas_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect"
                                        label="Jogador">
                                        <Form.Select aria-label="Jogador"
                                            onChange={handleJogadorChange}
                                            value={jogador_selected}>
                                            {jogadores_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card>
            <Card.Header>
                            <Card.Title>Melhor GR</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect" label="EquipaGR">
                                        <Form.Select aria-label="EquipaGR"
                                            onChange={handleEquipaGRChange}
                                            value={equipagr_selected}>
                                            {equipasgr_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect"
                                        label="GR">
                                        <Form.Select aria-label="GR"
                                            onChange={handleJogadorGRChange}
                                            value={jogadorgr_selected}>
                                            {jogadoresgr_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <br />
                    <Card>
                    <Card.Header>
                        <Card.Title>Melhor Claque</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row className='mb-3'>
                            <Col>
                                <FloatingLabel controlId="floatingSelect" label="Claque">
                                    <Form.Select aria-label="Claque"
                                        onChange={handleClaqueChange}
                                        value={claque_selected}>
                                        {claques_data}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <br />
                <Row className="mb-3">
                <Col className="d-flex justify-content-center align-items-center">
                    <Button variant="secondary" size="lg" onClick={voltar}>
                        Voltar
                    </Button>
                </Col>
                {finalizarDisponivel ?
                    (<Col className="d-flex justify-content-center align-items-center">
                        <Button variant="danger" size="lg" onClick={()=>finalizarJogo({melhorjogador: jogador_selected, melhorclaque: claque_selected, melhorgr: jogadorgr_selected})}>
                            Confirmar Finalizar
                        </Button>
                    </Col>) : null}
            </Row>
                </>
    );



}

export default FinalizarJogo;
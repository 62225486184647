import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  getEscaloes, getJogos } from '../../store/actions';
import { Row, Col, Spinner , Card,FloatingLabel, Form } from 'react-bootstrap';
import JogosTabSelect from './jogosTabSelect';
import { useState } from 'react';
import moment from 'moment';

const ls_campo = () => localStorage.getItem('campo_selected_jogos') || 'none';

const SelectJogos = ({admin}) => {
    const escaloes = useSelector( state=> state.escaloes);
    const jogos = useSelector( state=> state.jogos);
    const dispatch = useDispatch();
    const [campos, setCampos] = useState(null);

    const [{ campo_selected, dia_selected }, setData] = useState({
        campo_selected: ls_campo(),
        dia_selected: 0
    });

    function handleCampoChange(event) {
        setData(data => ({ campo_selected: event.target.value, dia_selected: 0 }));
        localStorage.setItem('campo_selected_jogos', event.target.value);
    }

    function handleDiaChange(event) {
        setData(data => ({ ...data, dia_selected: event.target.value }));
    }



    useEffect(()=>{
            dispatch(getEscaloes());
            dispatch(getJogos());
    },[dispatch])

    const calculaCampos = (jogos) => {
        const result = [];
        const uniqueCamposSet = new Set();

        for (const jogo of jogos) {
            const { campo, campos_id, campodesignacao } = jogo;
            if (!uniqueCamposSet.has(campos_id)) {
                result.push({ campos_id, campo, campodesignacao });
                uniqueCamposSet.add(campos_id);
            }
        }



        return result.sort((a,b)=> b.campos_id - a.campos_id);
    }

    useEffect(() => {
        if (jogos && jogos.jogos && jogos.jogos.length > 0) {
            const camposResult = calculaCampos(jogos.jogos);
            setCampos(camposResult);
        }
    }, [jogos]);

    const campos_data = campos ? [<option key="campos-0" value="none"></option>, campos?.map((item) => (
        <option key={`campos-${item.campos_id}`} value={item.campos_id}>
            {item.campodesignacao} - {item.campo}
        </option>
    ))] : null;

    const dia_data =  [
        <option key="dias-1" value="0">Todos</option>,
        <option key="dias-2" value="21">21</option>,
        <option key="dias-3" value="22">22</option>,
        <option key="dias-4" value="23">23</option>,
    ];

    const jogosfiltered = jogos.jogos ? jogos.jogos.filter(jogo => (campo_selected == 0 || jogo.campos_id == campo_selected) && (dia_selected == 0 || moment(jogo.hora).date() == dia_selected)) : [];

    return(
        <>
            { jogos.jogos && jogos.jogos.length && jogos.jogos.length > 0 ?  
        (<>

<Card>
                        <Card.Header>
                            <Card.Title>Selecionar Campos</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect" label="Campo">
                                        <Form.Select aria-label="Campo"
                                            onChange={handleCampoChange}
                                            value={campo_selected}>
                                            {campos_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect"
                                        label="Dia">
                                        <Form.Select aria-label="Dia"
                                            onChange={handleDiaChange}
                                            value={dia_selected}>
                                            {dia_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                { jogosfiltered && jogosfiltered.length && jogosfiltered.length > 0 ?  
                <JogosTabSelect admin={admin} jogos={jogosfiltered}/> : null
                }
                              
                </>
                )
                : 
                <Row className="mb-3">
                <Col className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="secondary" />         
                </Col>
                </Row>
                
                }
                </>
    )
}

export default SelectJogos;
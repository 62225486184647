import moment from 'moment';
import React, { useEffect, useState } from 'react';

function MyStopwatch({ horainicio, startClock, tempojogo }) {

  const [time, setTime] = useState(0);

  useEffect(() => {

    const calculateOffset = () => {

      const today = new Date();
      const startDate = new Date(horainicio);
      let offsetSeconds = parseInt(Math.abs(today.getTime() - startDate.getTime()) / 1000)
      return offsetSeconds * 1000;

    }

    let isActive = true;
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setTime(calculateOffset);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [tempojogo, startClock, horainicio]);

  return (
    <>
      {time > 0 ?
        (<div style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '25px' }}>
            {Math.floor(time / 60000) >= tempojogo ? <><span>{tempojogo}</span>:<span>00</span></> : <><span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}</span>:<span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span></>}
          </div>
        </div>) : null
      }
    </>
  );
}

export default MyStopwatch
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ListaJogos from './listajogos';

const JogosTabSelect = (props) => {

    const jogos = props.jogos;
    return (
        <>
            <Tabs defaultActiveKey={"jogos"} id={"tab_escalao"} className="mb-3">
                <Tab eventKey={"jogos"} title="Jogos">
                    <ListaJogos admin={props.admin} jogos={jogos.filter(jogo => jogo.estado !== '2')} />
                </Tab>

                <Tab eventKey={"finalizados"} title="Finalizados">
                    <ListaJogos admin={props.admin} jogos={jogos.filter(jogo => jogo.estado === '2')} />
                </Tab>
            </Tabs>
        </>
    )
}

export default JogosTabSelect;